/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.CheckoutPayment {
    &_isTabbedWidget {
        .MCWidget {
            &-PaymentMethodsWrapper {
                display: none;

                &_isSelected {
                    display: grid;
                }
            }
        }

        .FlagsWrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 12px;
            margin-block-start: 24px;
        }

        .MCTabbedWidget {
            &-InnerContent {
                display: none;

                h2 {
                    display: none;
                }

                .Field-Wrapper {
                    margin-inline-start: 40px;
                }

                &_isSelected {
                    display: flex;
                    flex-direction: column;

                    .Field {
                        margin-block-start: 0;
                    }
                }

                .MCWidget-PaymentMethodsWrapper {
                    display: none;

                    &_isSelected {
                        display: grid;
                    }
                }
            }
        }
    }
}
