/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.SliderWidget {
    &-Figure {
        overflow: hidden;
        height: 100svh;
        z-index: 100;
        scale: 1;
        transition-property: margin-block-start, scale;
        transition-duration: 1266ms, 1616ms;
        transition-delay: 0s, 1116ms;
        transition-timing-function: ease-in-out, ease-in-out;

        &::before {
            content: '';
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
        }
    }

    &-Figcaption {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 0;
        width: 100%;
        transform: translateY(-50%);
    }

    &-FigureImage {
        display: block;
        padding-block-end: 40%;

        @include mobile {
            padding-block-end: 100%;
        }

        img {
            object-fit: cover;

            @include mobile {
                object-position: center;
            }
        }

        &_isVideoPlaceholder {
            z-index: 10;
        }

        &_isVideoLoaded {
            position: absolute;
            z-index: -20;
        }
    }

    &-Wrapper {
        .BouncingArrow {
            position: absolute;
            width: 24px;
            height: 24px;
            inset-block-end: 120px;
            inset-inline-start: 0;
            inset-inline-end: 0;
            margin-inline-start: auto;
            margin-inline-end: auto;
            z-index: 1;

            @include mobile {
                inset-block-end: 52px;
            }
        }
    }

    &-Container {
        height: 100svh;

        path {
            opacity: 1;
            transition: opacity 500ms ease-in-out;
        }
    }

    .VideoContainer {
        background: transparent;
    }
}
