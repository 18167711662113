/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';

/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.CheckoutPayment {
    &_isMakeCommerce {
        .MCWidget {
            &-PaymentMethodsWrapper {
                margin-block-start: 24px;
                display: grid;
                gap: 12px;
                grid-template-columns: repeat(4, 1fr);

                &_logoSize {
                    &_s {
                        grid-template-columns: repeat(4, 1fr);
                        gap: 12px;
                    }

                    &_m {
                        grid-template-columns: repeat(3, 1fr);
                        gap: 12px;
                    }

                    &_l {
                        grid-template-columns: repeat(2, 1fr);
                        gap: 12px;
                    }
                }
            }
        }

        .Field-InputWrapper {
            display: flex;
        }
    }

    &_isMakeCommerceRoot {
        .MCWidget {
            &-PaymentMethodsWrapper {
                display: none;

                &_isSelected {
                    display: grid;
                    margin-block-start: 0;
                }
            }
        }
    }

    &-Description {
        padding-inline-start: 28px;
        font-size: .875rem;
        margin-block-start: 8px;
        margin-block-end: 0;
        color: #8E8E8D;
        font-weight: 400;
        width: max-content;
        text-align: left;
        white-space: break-spaces;
        width: 100%;
    }
}
