/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.StendersWidget {
    width: 90vw;
    margin: auto;

    &-Wrapper {
        width: auto;
        margin: 0 60px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        contain: content;

        @include tablet {
            margin: 0 16px;
        }

        @include mobile {
            flex-direction: column;
            margin: 0 16px;
        }
    }

    &-Image1 {
        flex-basis: 30%;

        @include desktop {
            flex-basis: unset;
            width: 452px;
            padding-block-start: 25vh;
        }
    }

    &-TitleAndContent {
        flex-basis: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-Title {
        align-self: center;
        font-weight: 400;
        font-size: 3.25rem;
        line-height: 100%;
        margin-block-end: 52px 0;

        @include mobile {
            text-align: start !important;
            font-size: 2.625rem;
            margin-block: 32px;
        }

        @include large-narrow-desktop {
            margin-inline-start: 40px;
        }
    }

    &-Content {
        align-self: flex-end;
        width: 70%;

        @include mobile {
            width: 100%;
        }
    }

    &-DescriptionWrapper {
        @include mobile {
            float: inline-end;
            max-width: 70%;
            margin-block-end: 32px;
        }
    }

    &-Description {
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 142%;
        margin-block-end: 24px;

        @include mobile {
            font-size: 1rem;
            line-height: 126%;
        }
    }

    &-DescriptionContent {
        white-space: break-spaces;
    }

    &-Link {
        text-transform: uppercase;
        font-size: 0.875rem;
        text-decoration: none;
        font-weight: 600;
        color: var(--link-color);

        &:visited {
            color: var(--link-color);
        }

        &:hover {
            color: var(--color-black);
        }
    }

    &-Image2 {
        margin-block-start: 300px;

        &_ruStore {
            margin-block-start: 380px;
        }

        @include desktop {
            margin-block-start: 52px;
        }
    }
}
